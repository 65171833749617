import { create } from 'zustand'
import { PriceRange } from '@/types/FoodMerchant'
import { Coords } from '@/types/Coords'

export type FilterState = {
  filters: string[]
  restrictions: string[]
  cuisine: string[]
  searchState: boolean
  selectedCard: number
  selectedCuisines: string[]
  query: string
  searchInput: string
  searchType: 'restaurants' | 'meals'
  priceFilter: PriceRange
  filterResetCounter: number
  isMobile: boolean
  coords: Coords
}

type FilterActions = {
  addFilter: (filters: string) => void
  removeFilter: (filters: string) => void
  setFilters: (filters: string[]) => void
  resetFilters: () => void
  setSearchState: (searchState: boolean) => void
  setSelectedCard: (selectedCard: number) => void
  setQuery: (query: string) => void
  setSearchType: (searchType: 'restaurants' | 'meals') => void
  setSearchInput: (searchInput: string) => void
  setPriceFilter: (priceFilter: PriceRange) => void
  setIsMobile: (size: number) => void
  incrementFilterResetCounter: () => void
  resetSelectedCard: () => void
  resetQuery: () => void
  updateCoords: (newValues: Partial<Coords>) => void
}

export type FilterStore = FilterState & FilterActions

export const initialState: FilterState = {
  filters: ['indie'],
  restrictions: [
    'gluten-free',
    'halal',
    'vegan',
    'low glycemic index',
    'heart healthy',
    'kosher',
    'low-lactose',
    'dairy-free',
    'valentines',
    'st patrick',
    'family-friendly',
  ],
  cuisine: [
    'chinese',
    'indian',
    'italian',
    'japanese',
    'korean',
    'mexican',
    'thai',
    'vietnamese',
  ],
  selectedCuisines: [''],
  searchState: true,
  selectedCard: 0,
  query: '',
  searchInput: '',
  searchType: 'restaurants',
  priceFilter: { min: 0, max: Infinity },
  filterResetCounter: 0,
  isMobile: false,
  coords: {
    latitude: 0,
    longitude: 0,
    city: '',
    stateCode: '',
  },
}

export const useFilterStore = create<FilterStore>()((set) => ({
  ...initialState,
  addFilter: (filters) =>
    set((state) => ({
      filters: [...state.filters, filters],
    })),
  removeFilter: (filters) =>
    set((state) => ({
      filters: state.filters.filter((f) => f !== filters),
    })),
  setFilters: (filters) =>
    set(() => ({
      filters: filters,
    })),
  resetFilters: () =>
    set(() => ({
      filters: initialState.filters,
    })),
  setSearchState: (searchState) =>
    set(() => ({
      searchState: searchState,
    })),
  setSearchType: (searchType) =>
    set(() => ({
      searchType: searchType,
    })),
  setSelectedCard: (selectedCard) =>
    set(() => ({
      selectedCard: selectedCard,
    })),
  setQuery: (query) => set(() => ({ query: query })),
  setSearchInput: (searchInput) => set(() => ({ searchInput: searchInput })),
  setPriceFilter: (priceFilter) => {
    set(() => ({
      priceFilter: { min: priceFilter.min, max: priceFilter.max },
    }))
  },
  incrementFilterResetCounter: () =>
    set((state) => ({ filterResetCounter: state.filterResetCounter + 1 })),
  resetSelectedCard: () =>
    set(() => ({
      selectedCard: initialState.selectedCard,
    })),
  resetQuery: () =>
    set(() => ({
      query: initialState.query,
    })),
  setIsMobile: (size) =>
    set(() => {
      if (size <= 768) {
        return { isMobile: true }
      }
      return { isMobile: false }
    }),
  updateCoords: (newValues) =>
    set((state) => ({ coords: { ...state.coords, ...newValues } })),
}))
